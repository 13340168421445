/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

function SEO({
  title = "Picap Blog",
  url = "https://blog.picap.app/",
  description = "Ultimas noticias Picap",
  keywords = "blog picap, pibox, pilife, pihome"
}) {
  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      <meta property="og:url" content={url} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
    </Helmet>
  );
}

SEO.propTypes = {
  lang: PropTypes.string,
  url: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string
};

export default SEO;
