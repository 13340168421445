import React from "react";
import PropTypes from "prop-types";
import Header from "../Header";
import SEO from "../Seo";
// Styles
import { Container, Content, Blog, Footer } from "./styles";

const Layout = ({ children }) => {
  return (
    <Container>
      <SEO />
      <Header />
      <Content>
        <Blog>{children}</Blog>
      </Content>
      <Footer>

      </Footer>
    </Container>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
