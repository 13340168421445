import styled from "styled-components";
import { device } from "../../../styles/device";

// Layout
export const Container = styled.div`
  margin: 0 auto;
  
  
  @media ${device.mobileL} {
    max-width: 410px !important;
  }
`;

export const Content = styled.div`
  margin: 0 auto;
`;

export const Blog = styled.main`
  margin-top: 150px;
  display: flex;
  justify-content: center;
`;

export const Footer = styled.footer`
  height: 57px;
`;
