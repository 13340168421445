import React from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reduxThunk from "redux-thunk";
import reducers from "./reducers";

const store = createStore(
  reducers, // Reducers
  {}, // Estado inicia
  applyMiddleware(reduxThunk)
);

const App = ({ children }) => <Provider store={store}>{children}</Provider>;

export default App;
