import styled from "styled-components";
import { device } from '../../../styles/device';
import { Link as ReachLink } from "gatsby";

// Header
export const Container = styled.div`
  background: linear-gradient(to right, #692b7c, #ff0068);

  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
`;

export const Headers = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  padding: 0 60px;
  @media ${device.tablet} {
     padding: unset;
  }
`;

export const Link = styled(ReachLink)`
  & > img {
    width: 100px;
    margin: 0;
  }
`;

export const SearchForm = styled.div`
    display: flex;
    justify-content: center;
`;

export const Input = styled.input`
    width: 200px;
    padding: 5px;
    font-weight: 16px;
`;