import React from "react";
// Styles
import { Container, Link, Headers, SearchForm, Input } from "./styles";
// Images
import Brand from "../../../images/picap-brand.svg";
import { GoSearch } from "react-icons/go";
import { MdFilterList } from "react-icons/md";

const Header = () => {
  return (
    <Container>
      <Headers>
        <Link to="/page">
          <img src={Brand} alt="Picap Blog" />
        </Link>
        <div>
            <SearchForm>
                <Input type="text"/>
                <GoSearch
                    color={"#FFF"}
                    size={"32px"}
                    style={ { cursor: "pointer", marginLeft: "10px", marginRight: "10px" } }
                />
                <MdFilterList
                    color={"#FFF"}
                    size={"32px"}
                    style={ { cursor: "pointer", marginLeft: "10px" } }
                />
            </SearchForm>
        </div>
      </Headers>
    </Container>
  );
};

export default Header;
