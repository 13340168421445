import { SET_ARTICLES, SET_CATEGORIES, SET_FT_ARTICLE } from "../types";

const INITIAL_STATE = {
  articles: [],
  categories: [],
  first_post: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ARTICLES:
      return { ...state, articles: action.payload };

    case SET_CATEGORIES:
      return { ...state, categories: action.payload };

    case SET_FT_ARTICLE:
      return { ...state, first_post: action.payload };

    default:
      return state;
  }
};
